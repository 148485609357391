// Generated by Framer (2372734)

import { addFonts, cx, CycleVariantState, RichText, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import * as sharedStyle from "../css/TZDFurpkd";

const cycleOrder = ["Dp1Q31VHQ"];

const variantClassNames = {Dp1Q31VHQ: "framer-v-e34u1s"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const getProps = ({height, id, width, ...props}) => { return {...props} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale } = useLocaleInfo()

const {style, className, layoutId, variant, NP5yQIcR3wZ6tVEahQ, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "Dp1Q31VHQ", transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const defaultLayoutId = React.useId()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-lA91A", sharedStyle.className, classNames)} style={{display: "contents"}}>
<Transition value={transition}><motion.div {...restProps} className={cx("framer-e34u1s", className)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"Dp1Q31VHQ"} ref={ref} style={{...style}}><RichText __fromCanvasComponent children={<React.Fragment><motion.p className={"framer-styles-preset-1jf7vrb"} data-styles-preset={"TZDFurpkd"} style={{"--framer-text-color": "var(--extracted-r6o4lv)"}}>Label</motion.p></React.Fragment>} className={"framer-m55c9h"} layoutDependency={layoutDependency} layoutId={"qnBrdBskT"} style={{"--extracted-r6o4lv": "var(--token-5e34ca85-650e-4845-8b6a-e411ea113e40, rgb(229, 229, 229))", "--framer-paragraph-spacing": "0px"}} text={NP5yQIcR3wZ6tVEahQ} verticalAlignment={"top"} withExternalLayout/></motion.div></Transition>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-lA91A [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-lA91A .framer-1fdv789 { display: block; }", ".framer-lA91A .framer-e34u1s { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 0px; height: min-content; justify-content: center; overflow: visible; padding: 0px 4px 0px 4px; position: relative; width: min-content; }", ".framer-lA91A .framer-m55c9h { -webkit-user-select: none; flex: none; height: auto; overflow: visible; position: relative; user-select: none; white-space: pre; width: auto; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-lA91A .framer-e34u1s { gap: 0px; } .framer-lA91A .framer-e34u1s > * { margin: 0px; margin-left: calc(0px / 2); margin-right: calc(0px / 2); } .framer-lA91A .framer-e34u1s > :first-child { margin-left: 0px; } .framer-lA91A .framer-e34u1s > :last-child { margin-right: 0px; } }", ...sharedStyle.css]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 24
 * @framerIntrinsicWidth 53.5
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]}}}
 */
const FramerB2cU3vp__: React.ComponentType<Props> = withCSS(Component, css, "framer-lA91A") as typeof Component;
export default FramerB2cU3vp__;

FramerB2cU3vp__.displayName = "Segmented Button Item";

FramerB2cU3vp__.defaultProps = {height: 24, width: 53.5};

addFonts(FramerB2cU3vp__, [...sharedStyle.fonts])